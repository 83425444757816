@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
};
